import Image from "next/image";

import Text from "ab-text";
import Link from "ab-link";

import "./ab-text-photo.scss";

const TextPhoto = ({
  image,
  imageMobile,
  imagemobile,
  title,
  description,
  linkText,
  href,
  animated,
  link,
}) => {
  return (
    <div className="ab-text-photo">
      <Image
        src={image?.mediaItemUrl || image || '/images/404.png'}
        alt={title}
        layout="fill"
        id="desktop"
      />
      <Image
        src={imagemobile?.mediaItemUrl || imageMobile || '/images/404.png'}
        alt={title}
        layout="fill"
        id="mobile"
      />

      <Text element="h2" animated={animated}>
        {title}
      </Text>
      <Text>{description}</Text>

      {linkText ? <Link prefetch={false} text={linkText} href={href} /> : null}
      {link ? (
        <Link prefetch={false} text={link.title} href={link.url} />
      ) : null}
    </div>
  );
};

export default TextPhoto;
